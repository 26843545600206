@font-face {
    font-family: Cairo;
    src: url(../src/static/fonts/Cairo/Cairo-Regular.ttf);
  }
  @font-face {
    font-family: CairoBold;
    src: url(../src/static/fonts/Cairo/Cairo-Bold.ttf);
  }
body{
    overflow-x: hidden;
    font-family: Cairo;
}
body *, div.content-1, div.content-2{
    font-family: Cairo;
}

.font-cairo-bold,  #contact button.submit, .customize-btn, #services h3 {
    font-family: CairoBold;
}

header {
    background-image: none;

}

a{
    text-decoration : none !important;
}
.vc_single_image-wrapper {
    background-image: url(../src/static/img/img5.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 460px !important;
}

@media only screen and (max-width: 767px){
    #nav-wrap:target > ul#nav{
        background-color: #e6e6e6;
    }

    ul#nav li {
    border-bottom-style: solid;
}
}

ul#nav li a {
    display: inline-block;
    padding: 8px 13px;
    line-height: 32px;
    font-size: 16px !important;
    text-decoration: none;
    text-align: left;
    -webkit-transition: color .2s ease-in-out;
    -moz-transition: color .2s ease-in-out;
    -o-transition: color .2s ease-in-out;
    -ms-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out;
    letter-spacing: 0px;
    font-family: CairoBold;
}
body *, div, span, button, a {
    letter-spacing: 0px !important;
}


nav#nav-wrap.current ul#nav li a{
    color : #fff;
}
nav#nav-wrap.opaque ul#nav {
    background-color: #121652;
}

nav#nav-wrap.opaque ul#nav li a{
    color : #fff;
}

nav#nav-wrap.opaque ul#nav li.current a{
    color: #00A5DF;
    border-bottom: 2px solid #00A5E0;
}


.elementor-image, .elementor-image-w{
    
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 80px;
    height: 17px;
    width: 85px;
    max-height: 25px;

}
.elementor-image{
    background-image: url(./static/img/Logo-Hor-2.png);
}
.elementor-image-w{
    background-image: url(./static/img/logo-w.png);

}

.Wrapper-image{
    max-width: 200px;
    display: inline-block;
}

.wrapper-image-w{
    max-width: 200px !important;

}


.customize-icons {

}

.customize-icons, .customize-icons.icons-about{
    font-size: 38px;
    color: #121652;
}

.customize-icons.icons-about{
    color : #eaeaea;
}
ul#nav{
    text-align: left;
}

.info-box-title, .info-box-title-2{
    margin: -35px 0 10px -5px;
    padding : 0 10px;
    width: fit-content;
    background: #fff;
    font-family: CairoBold;
    font-weight: 600;
    text-align: left;
}
.info-box-title{
    color:#121652;
}

.info-box-title-2{
    color: #fff;;
}
.content-1, .content-2{
    color: #656565;
    font-family: Cairo;
    font-weight: 400;
}

.info-box-2-content-1{
    color : #e6e6e6;
    font-family: Cairo;
    font-size: 16px;
    font-weight: 400;
}

.info-box{
    padding: 20px !important;
    box-shadow: none;
    border-radius: 10px;
    border : 1px solid #00A5DF;
}

.row{
    width: 100%;
    max-width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;

}

.jumbotron-content{
    font-size: 20px;
    color: #ffffff;
    text-align: center;
}

.wrappwer-second-section{
    background-image: url(./static/img/content-bg2-5.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.wrapper-light{
    background: #fff;
    padding: 50px 0;
}

#about p.company-history-paragraph, .services-content-p {
    line-height: 1.7em;
    color: #000;
    font-size: 14px;
}
#about{
    padding-bottom: 0px;
}
.main-home-first-section{
    padding: 0;
}

.services-content{
    background-color: #FFFFFF;
    padding: 0 0 20px;
    box-shadow: none;
    border-style: solid;
    border-width: 0px 0px 0px 3px;
    min-height: 525px;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #e5e7eb;
    min-width: 80%;
    margin: 0 auto;
    width: 80%;
    max-width: 80%;
}
.services-content h3:nth-child(2), .services-content div.services-content-p {
    padding: 0 20px 0 20px ;
    margin-top: 10px;
}
.services-content-2{
    background-color: #FFFFFF;
    margin: 20px;
    box-shadow: none;
    border-style: solid;
    border-width: 0px 0px 0px 3px;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #e5e7eb;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.services-content:hover{
    /* background: #4285f4;
    border-width: 0px;
    -webkit-box-shadow: none;
    box-shadow: none !important; */
}
.services-content:hover *, .services-content:hover ul li{
    /* color: #fff !important; */
}
#services h3 {
    font-size: 16px;
}
.services-content-p ul{
    list-style-type: disc;
    padding: 0 20px;
}

.services-content-p ul li{
    margin-bottom: 5px;
}
.main-home-first-section-p{
    min-height: 120px;
    margin: 0 40px 50px;
}


.our-sevices-container{
    margin : 0 auto !important
}

header .banner {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    width: 100%;
    padding-bottom: 30px;
    text-align: left;
    padding-top: 100px;
    height: 100%;
    background: url(./static/img/home-img.jpg);
    background-size: cover;
    object-fit: fill;
    background-position: center;
}

header .banner-text h1 {
    font: 90px/1.1em 'opensans-bold', sans-serif;
    color: #121652;
    letter-spacing: -2px;
    margin: 0 auto 18px auto;
}

.icon-down-circle{
    color :#121652
}

.img-header-logo{
    margin: 5px;
    width: 160px !important;
    max-width: 160px !important;
    height: 40px;
    max-height: 36px;
    }

header .banner-text h3 {
    /* color: #fff !important; */
    margin: 10px 15px;
    width: 90%;
    max-width: 90%;
    text-align: center;
    font-size: 24px;
}

header .banner-text h3:first-child{
    font-size: 34px;
    font-family: CairoBold;
}
header .banner-text h3:nth-child(2){
    font-size: 22px !important;
    line-height: 1.6;
    font-family: cairoBold;
}

 #go-top a:hover {
    background-color: #00A5DF;
}

/* our teams */
.flip-card {

    width: 300px;
    min-height: 500px;
    perspective: 1000px;
    padding: 20px;
}


  .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        background: #fff;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
        border-radius: 5px;
    }


  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-card-front {
    color: black;
  }

 .flip-card-front span, .flip-card-back span{
    color: #00A5DF;
    font-size: 14px;
    display: inline-block;
    margin-top: 5px;
    font-weight: 600;
}

  .flip-card-back {
    background-color: #fff;
    color: #727272;
    transform: rotateY(180deg);
    padding: 20px;
    text-align: center;
    border-radius: 5px;
  }
  .flip-card-back p{
      font-size: 12px;
  }
  .item .item-wrap{
      background: none;
  }

  .flip-card-back ul{
      list-style-type: none;
      text-align: left;
      padding: 0 2px;
  }

  .flip-card-back ul li{
    margin-bottom: 0px;
    color: #000;
    font-size: 12px;
  }

  #OurTeam h1 {
    font-size: 2rem;
    text-transform: capitalize;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #121652;
    font-weight: bold;
    text-align: center;
    font-size: 14px;
    padding: 30px 0 20px;
}
#OurTeam h1.our-team-title{
    font-size: 32px;
}
#OurTeam{
    background: #f9f9f9;
    padding: 100px 0;
}
.item .item-wrap img {
    vertical-align: middle;
    margin-top: 25%;
    margin-bottom: 10%;
}
.service-icon{
    display: flex;
    margin: 0 auto;
    width: 100%;
    height: 75px;
    background: #121652;
    align-self: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 65% 66%;
}
.service-img{
    width: 100%;
    border-radius: 4px 4px 0 0px;
    -webkit-border-radius: 4px 4px 0 0px;
    -moz-border-radius: 4px 4px 0 0px;
    -ms-border-radius: 4px 4px 0 0px;
    -o-border-radius: 4px 4px 0 0px;
    max-height: 250px;
}
.col-service-wrapper{
    margin-bottom: 85px;
}
.service-icon-1{
    background-image: url(./static/img/services.jpg) ;
}

.service-icon-2{
    background-image: url(./static/img/services.jpg);
}

.service-icon-3{
    background-image: url(./static/img/services.jpg);
}

.service-icon-4{
    background-image: url(./static/img/services.jpg);
}
.service-icon-5{
    background-image: url(./static/img/services.jpg);
}
.service-icon-6{
    background-image: url(./static/img/services.jpg);
}

.icon-about .customize-icons{
    font-size : 14px;
    margin : 0 5px;
}
.ul-check.mb-5.list-unstyled.success{
    padding-inline-start: 20px !important;
    font-size : 14px;
}
h2.section-title.mb-3 {
    font-size: 42px !important;
    text-align: center;
    padding-top: 40px;
}
.App{
    width: 100%;
    overflow-x: hidden;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 300px){
    header .banner-text {
        margin-top: 0;
    }
    header .banner-text h3, #about h2, #about p, h3{
        font-size: 11px !important;
        line-height: 20px;
    }

    header .banner-text h3{
        margin : 0px 10px;
    }
    .img-header-logo {
        width: 210px !important;
        max-width: 210px!important;
        height: 45px;
        max-height: 45px;
    }
    h2.section-title.mb-3 {
        font-size: 20px !important;

    }
    .customize-icons, .customize-icons.icons-about {
        font-size: 28px;
    }

}
@media only screen and (max-width: 400px) {
    header .banner-text {
        margin-top: 0;
    }
    header .banner-text h3, #about h2{
        font-size: 16px;
    }
    h2.section-title.mb-3, #services h3:first-child{
        font-size: 24px !important;
    }
    #about p, .ul-check.mb-5.list-unstyled.success li, .services-content-p ul li{
        font-size: 14px;
    }
    .customize-icons, .customize-icons.icons-about {
        font-size: 34px;
    }
    .service-icon {
        width: 60px;
        height: 60px;
        margin-top: -50px;
    }
    .services-content {
        min-height: 280px;
    }
    header .banner-text h3{
        margin : 0 15px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
    header .banner-text {
        margin-top: 0;
    }
    header .banner-text h3, #about h2{
        font-size: 18px;
    }
    #about h2{
        padding-top: 15px;
    }
    h2.section-title.mb-3, #services h3:first-child{
        font-size: 32px !important;
    }
    #about p, .ul-check.mb-5.list-unstyled.success li, .services-content-p ul li{
        font-size: 14px;
    }
    .customize-icons, .customize-icons.icons-about {
        font-size: 34px;
    }
    .service-icon {
        width: 60px;
        height: 60px;
    }
    .services-content {
        min-height: 508px;
    }
    header .banner-text h3{
        margin : 0 20px;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    #nav-wrap:target > ul#nav {
        width: 185px;
    }
}

@media only screen and (max-width: 767px)
{
    #nav-wrap:target > ul#nav {
       width: 225px;
       float: left !important;
       right: 0px;
       top: 48px;
       position: fixed;
       margin: 0px;
      background-color: #121652;
    }
    ul#nav li:last-child{
        border-bottom: none;
    }
    #nav-wrap:target > ul#nav{
        padding: 10px ;
    }

}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}


.widget.widget_contact{
    font-size: 16px;
}

.auditing-bg{
    background-color: #f2f2f2;
    background-position: center;

    background-repeat: no-repeat;
    height: 300px;
    max-height: 300px;
}
.auditing-bg-1{
    background-image: url(../src/static/img/img5.jpg);
    background-size: cover;

}

.auditing-bg-2{
    background-image: url(../src/static/img/img6.jpg);
    background-size: cover;

}
.first-img{
    background-image: url(../src/static/img/img7.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 500px;
}

.contact-wrapper{
    background: #fff;
    padding: 0px;
    border-radius: 5px;
    box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
}
.address-wrapper{
    background-color: #121652;
}



#services h3:first-child, #about h2.section-title:first-child{
    padding: 30px 0 20px;
    color: #121652;
    font-size: 32px;
    font-family: CairoBold;
}
 
.contact-title{
    color: #121652;
    font-size: 32px;
    padding: 30px 0 20px;
}

.site-section div .site-section-content{
    margin-right: 10px;
    margin-left: 10px;

}

.head-content{
    font-size: 14px !important;
    padding: 0 15px;
    color: #000 !important;
}



.text-primary, .text-primary h3{
color: #121652 !important;
}

.text-second{
    color: #00A5DF;
}

.service-content-wrapper-2{
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #e7e7e8;
    box-shadow: 1px 4px 8px 0px rgb(24 25 30 / 4%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 10px 10px 10px 10px;
    width: 50;
    min-height: 120px;
    background: #fff;
}
.carousel-root{
   
    margin-left: auto;
    margin-right: auto;
}
.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000 !important;
}
.carousel .control-next.control-arrow:before {
    border-left: 8px solid #000 !important;
}
.carousel .slide{
    text-align: left !important;
}
.carousel .control-dots{
    display: none;
}

.carousel .slide div{
    width: 80%;
    margin : 0 auto;
    /* background: rgb(255 255 255 / 50%);
    border: 1px solid rgb(255 255 255 / 50%);
    border-radius: 4px; */
    padding: 30px 0;
}


.carousel.carousel-slider .control-arrow{
    background: rgba(0,0,0,0.2);
}
.carousel-status{
    display: none;
}

.top-header{
    padding: 10px 0 !important;
    background: #f2f2f2;
    color: #121652;
    min-height: 40px;
    font-family: CairoBold;
}

.top-header *{
    font-family: CairoBold;
    font-size: 14px;
}
.social-links *{
    font-family: 'FontAwesome';
    font-size: 16px;
    margin: 0 5px !important;
}
i.text-primary{
    color: #121652 !important;
}

@media only screen and (max-width: 767px)
{
    header .banner-text h3:nth-child(2) {
        font-size: 12px !important;
    }
    .carousel .slide div{
        width: 80%;
    }
}

select{
    background: #fff;
    border-radius: 50px;
    padding: 9px 15px;
    margin-bottom: 5px  !important;
    color: #121652;
    font-size: 14px !important;
}


/* ************* app rtl ********/ 

.rtl, .rtl * {
    direction: rtl;
}

.slider-wrapper.axis-horizontal * {
    direction: ltr;
}

.rtl .App header nav#nav-wrap ul li.wrapper-image-li{
    margin-left: 18%;
    margin-right: 0;
}

.ltr .App header nav#nav-wrap ul li.wrapper-image-li{
    margin-right: 18%;
    margin-left: 0;
}

.ltr .App header nav#nav-wrap .top-header div .selection-language{
    margin-right: 40px;
    margin-left: 0;
}

.rtl .App header nav#nav-wrap .top-header div .selection-language{
    margin-left: 75px;
    margin-right: 0px;
}

.rtl #OurTeam div.row div.twelve.columns div#portfolio-wrapper div.item div.item-wrap .flip-card .flip-card-inner .flip-card-back ul{
    text-align: right;
}

.astric{
    font-size: 20px;
    font-weight: bold;
    padding: 4px 5px;
    max-height : 17px !important;
}

.team-title-position {
   padding:  30px 0 0px !important
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header, .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {

    font-size: 14px !important; 
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    margin: 5px !important;
}

.react-datepicker__month-container{
    width: 250px !important;
}

#contact input, #contact textarea, #contact select {
    padding: 10px 20px;
    color: #000;
    background: #f2f2f2;
    border: 1px solid #f2f2f2;
    border-radius: 3px;
    margin-bottom: 0px !important;
    outline: none;
    font-size: 15px;
    line-height: 24px;
    width: 65%;
}


.wrapper-loader{
    position: absolute;
    height: 160vh;
    width: 100%;
    right: 0;
    left: 0;
    z-index: 9;
    background: rgb(0 0 0 / 50%);
}
